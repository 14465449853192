import React from "react"
const EmailCon = () => {
 const handleLogout = () =>{
     localStorage.clear();
     window.location = 'https://newcotech.tap2sos.com/login';
 }

    return(
        <div className ="m-4 container main-body" style={{'background-color':'#428bca'}}>
            <div className ="d-flex flex-column align-items-center text-center">
            <img width ="400" src="https://newcotech.io/wp-content/uploads/2021/11/logo_web_newco.png" />
            </div>
            <div className="confcont">
                <h2 style={{'color':'white'}}>Thank you, for registering to Tap2sos</h2>
                <h4 style={{'color':'white'}} >An e-mail with a unique link has been sent to the user's email address!!</h4>
                <button className="mb-4" onClick={() => window.location = 'mailto:newco@tap2sos.com'}>Contact Us</button>
                <button className="mb-8" onClick={() => window.location = 'https://newcotech.tap2sos.com/register'}>Create New</button>
                <button className="mb-8" onClick={handleLogout}>Log Out</button>
            </div> 

        </div>

    );
}
export default EmailCon;
