import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login              from "./components/views/Login";
import Register           from "./components/views/Register";
import TapUser            from "./components/views/TapUser";
import TapUserBracellet   from "./components/views/TapUserBracellet";
import Emailcon           from "./components/views/Emailcon";
import BracelletRegister  from "./components/views/BracelletRegister";
import ProtectedRoute     from "./components/views/ProtectedRoute";
import BracelletEmailCon  from "./components/views/BracelletEmailcon";
import errPage from "./components/views/PagenotFound";


const Auth = () => {
  

  return (
    <Router>
      <Switch>
        <Route path="/bracelletregister/:encryptedID/"   component={BracelletRegister} />
        {/* <Route path="/bracelletregister"   component={BracelletRegister} /> */}
        <Route path="/bracelletuser/:encryptedId"       component={TapUserBracellet} />
        <Route path="/login"                            component={Login} />
        <ProtectedRoute path="/subsconfirm"             component={Emailcon}/>
        <Route path="/bracelletsubsconfirm"             component={BracelletEmailCon}/>
        <ProtectedRoute path="/register"                component={Register} />
        <Route path ="/404"                             component={errPage}/>
        <Route path="/tapuser/:username"                component={TapUser} />
        {/* <Route path="/forgot-password" component={Forgot} /> */}
       
       <Route path="/"                                 component={Login} />
       
     </Switch>
    </Router>
  );
};

export default Auth;