import { useState, useEffect } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';
import Web3 from 'web3'
import myJsonData from '../contracts/Tap2SosUsers.json';

const TapUser = () => {
 
    // Web3 - Blockchain Initializations 
    const web3            = new Web3('https://rpc.api.moonbase.moonbeam.network')
    const devAccount      = "0x453264bA6b8Bc1d071ccc5cce3661F3339A0cE1c"
    const contractAddress = "0xD8B0094Bdd3F430aE5ec5cA2c1927fCf85a2504A"
    const tapUsersABI     = myJsonData.abi       
    const [isReady, setIsReady] = useState(false);
    const [users, setUsers] = useState([])

    let { username } = useParams();
    console.log(username);
   
    // Get User's Data from Blockchain Based on User's ID
    const getUserBasedOnUsername = async (username) => {
         const myContract = await new web3.eth.Contract(tapUsersABI, contractAddress)  
         const getData    = await myContract.methods.getUserBasedOnUsername(username).call({from: devAccount})
         console.log(`Data for id ${username} = \n`, getData)
        setUsers(getData);
    }

      useEffect(() => {
          console.log("User's Username:", username)
          getUserBasedOnUsername(username) // userIndexID
          setIsReady(true);
          
      }, [])

      if (users.username === ''){
        
        window.location.replace(`https://newcotech.tap2sos.com/404/`);
      } 
      
      else {
        
        if(!isReady) {
          return (
          
           <div className="loading">Loading&#8230;</div>)}
        return (
          <div className="viewuser">
          <div className="container">
          <div className="main-body">
              <div className="d-flex flex-column align-items-center text-center">
              <img width="400" src="https://newcotech.io/wp-content/uploads/2021/11/logo_web_newco.png" />
              </div>
              <div className="row gutters-sm">
                <div className="mb-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <div className="mt-3">
                          <img src="https://emser.es/wp-content/uploads/2016/08/usuario-sin-foto.png" weight="150" height="150"/>
                          <h4>{users.name + " " +  users.surname}</h4>
                          <p className="text-secondary mb-1">Στοιχεία Χρήστη</p>
                        </div>
                      </div>
                    </div>
                  </div>
                
                <div className="mt-3 p-3">
                  <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-5">
                            <h6 className="mb-0">Όνομα</h6>
                            </div>
                            <div className="col-lg-7 text-secondary">
                            {users.name}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-lg-5">
                            <h6 className="mb-0">Επώνυμο</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                              {users.surname}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-lg-5">
                            <h6 className="mb-0">Email</h6>
                            </div>
                            <div className="col-lg-7 text-secondary">
                            {users.email}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-lg-5">
                            <h6 className="mb-0">Ιατρικές Παθήσεις</h6>
                            </div>
                            <div className="col-lg-7 text-secondary">
                            {users.medicalcondition}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-lg-5">
                            <h6 className="mb-0">Δωρητής Οργάνων</h6>
                            </div>
                            <div className="col-lg-7 text-secondary">
                            {users.organdonor}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-sm-5">
                            <h6 className="mb-0">Ομάδα Αίματος</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                            {users.bloodType}
                            </div>
                        </div>
                      <hr/>
                        <div className="row">
                            <div className="col-sm-5">
                            <h6 className="mb-0">Φύλο</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                            {users.gender}
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-sm-5">
                            <h6 className="mb-0">Τηλέφωνο Έκτακτης Ανάγκης</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                            {users.telnumber}
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-sm-5">
                            <h6 className="mb-0">Τηλέφωνο Ιατρού</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                            {users.doctelnumber}
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-sm-5">
                            <h6 className="mb-0">Ημερομηνία Γέννησης</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                            {users.birthday}
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
                  </div>
                </div> 
            </div>
        </div>
        </div>
        )
  }
      
};

export default TapUser;
