import React from "react"

const errPage = () => {

    

    return(
    <div className = "body">
        <div className="mars"></div>
        <img src="https://assets.codepen.io/1538474/404.svg" className="logo-404" />
        <img src="https://assets.codepen.io/1538474/meteor.svg" className="meteor" />
        <p className="title">Oh no!!</p>
        <p className="subtitle">
            You’re either misspelling the URL <br /> or requesting a page that does not  exist.
        </p>
       
        <img src="https://assets.codepen.io/1538474/astronaut.svg" className="astronaut" />
        <img src="https://assets.codepen.io/1538474/spaceship.svg" className="spaceship" /></div>
      );
}
export default errPage;