import { useState, useEffect } from "react";
import Form from "../../utilities/Forms";
import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";
import Select from 'react-select';
//  import {useParams} from "react-router-dom";
import Web3 from 'web3'
import myJsonData from '../contracts/Tap2SosUsers.json';
import Popup from './Popup';

const Register = () => {
  var [username,setUser] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birth,setBday] = useState("");
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({});
  const [medicalCondition,setCondition] = useState("");
  const [doctorsPhone,setdoctorsPhone] = useState("");
  const [phone,setPhone] = useState("");
  const [bloodType,setblood] = useState("");
  const [sexid,setSex] = useState("");
  const [organDonor, setDonor] = useState("");
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false); 
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    (username && name && surname && birth && email && medicalCondition && doctorsPhone
        && phone && bloodType && sexid && organDonor && agree1 && agree2 ) ? setDisable(false) : setDisable(true);
  },  [username, name, surname, birth, email, medicalCondition,
       doctorsPhone, phone, bloodType, sexid, organDonor, agree1, agree2]);
        

 // Web3 - Blockchain Initializations 
 // !!!! STORE TO .env FILE !!!! 
 const web3            = new Web3('https://rpc.api.moonbase.moonbeam.network')
 const devAccount      = "0x453264bA6b8Bc1d071ccc5cce3661F3339A0cE1c"
 const devPrivateKey   = "19318b8634925ea867dede4398155e6d0f9b44258a7dc78dd93c58bf0b5491ea"
 const contractAddress = "0xD8B0094Bdd3F430aE5ec5cA2c1927fCf85a2504A"
 const tapUsersABI     = myJsonData.abi       // Smart Contract's ABIs 
 const instance        = new web3.eth.Contract(tapUsersABI, contractAddress)
 var   transaction_hash;                                                                                            


 const togglePopup1 = () => {
  setIsOpen1(!isOpen1);
 }
 const togglePopup2 = () => {
   setIsOpen2(!isOpen2);
 }
  
 const handleClick= () => {
 setDisable(true)
 setLoading(true)
 }

 const checkboxHandler1 = () => {
     setAgree1(!agree1);    
 }
 const checkboxHandler2 = () => {
     setAgree2(!agree2);
 }

 // handle onChange event of the dropdown
 const handleChange = e => {
   setDonor(e.value);
 }
   const Donor = [
     {
       label: "Όχι",
       value: "No",
     },
     {
       label: "Ναι",
       value: "Yes",
     },
   ];

 // handle onChange event of the dropdown
 const handleChangeblood = e => {
   setblood(e.value);
 }
  const blood = [
   
   {
     label: "A+",
     value: "A+",
   },
   {
     label: "A-",
     value: "A-",
   },
   {
     label: "B+",
     value: "B+",
   },
   {
     label: "B-",
     value: "B-",
   },
   {
     label: "O+",
     value: "O+",
   },
   {
     label: "O-",
     value: "O-",
   },
   {
     label: "AB+",
     value: "AB+",
   },
   {
     label: "AB-",
     value: "AB-",
   },
 ];
 
 // handle onChange event of the dropdown
 const handleChangesex = e => {
   setSex(e.value);
 }
 const gender = [
   
  {
    label: "Προτιμώ να μην απαντήσω",
    value: "Other",
  },  
  {
    label: "Άρρεν",
    value: "Male",
  },
  {
    label: "Θήλυ",
    value: "Female",
  },{
    label: "Άλλο",
    value: "Οther"
  }
];

  /*requires for every array */
  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      username: {
        value: username,
        isRequired: true,
      },
      name: {
        value: name,
        isRequired: true,
      },
      surname: {
        value: surname,
        isRequired: true,
      },
      birth: {
       value: birth,
       isRequired: true,
     },
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
      },
      medicalCondition: {
        value : medicalCondition,
        isRequired: true,
      },
      doctorsPhone : {
        value : doctorsPhone,
        isRequired : true,
      },
      phone : {
        value : phone,
        isRequired : true,
      },
      blood : {
        value : blood,
        isRequired : true,
      },
      gender : {
        value : gender,
        isRequired : true,
      },
      Donor : {
        value : Donor,
        isRequired : true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const register = async (e) => {
    e.preventDefault();
    handleClick()

    const validate = validateRegister();
    if (validate) {
        setUser("");
        setValidate({});
        setName("");
        setSurname("");
        setEmail("");
        setBday("");
        setPhone("");
        setdoctorsPhone("");
        setCondition("");       
        
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        let length = 20;
        const charactersLength = characters.length;
        for ( let i = 0; i < charactersLength; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = result.substring(0, length);

        console.log(username)
        username = username.trim() + (result).trim()
        console.log("encrypted username:", username)

        console.log(name)
        console.log(surname)
        console.log(email)
        console.log(medicalCondition)
        console.log(organDonor)
        console.log(bloodType)
        console.log(sexid)
        console.log(phone)
        console.log(doctorsPhone)
        console.log(birth.toString())
      }
  
      // Dev's Account Balance
      const balanceFrom = web3.utils.fromWei(await web3.eth.getBalance(devAccount), 'ether');
      console.log(`The balance of ${devAccount} is: ${balanceFrom} ETH`);

      // send data to smart contract
      const myData = instance.methods.setUserData(username, name, surname, email, medicalCondition, organDonor, bloodType, sexid, phone, doctorsPhone, birth.toString()).encodeABI()
    
      const mycontract = async() => {
        const createTransaction = await web3.eth.accounts.signTransaction({
            from:     devAccount,
            to:       contractAddress,
            data:     myData,
            value:    web3.utils.toHex(web3.utils.toWei('0', 'ether')),
            gasLimit: web3.utils.toHex(2100000),
            gasPrice: web3.utils.toHex(web3.utils.toWei('6', 'gwei')),
      }, devPrivateKey)
          
      await web3.eth.sendSignedTransaction(
        createTransaction.rawTransaction
      )
      .on('transactionHash', async (txHash) => {
        transaction_hash = txHash
        console.log("Transaction Hash:", transaction_hash)

      })
      .on('error', (error) => {
          console.log("Error:", error)
      })
      }   
      await mycontract()
      
      console.log("User Added Succesfully to the Blockchain, Redirecting...")

      // Get All User's Data  
      const getUserData = async () => {
        const myUsersContract = await new web3.eth.Contract(tapUsersABI, contractAddress)  
        const getData = await myUsersContract.methods.getUsers().call({from: devAccount})
        return getData;    
      }
      getUserData().then( data => {
        console.log(data)
      })

      // Get Last User ID added from Blockchain 
      const getLastUserID = async () => {
        const myUsersContract = await new web3.eth.Contract(tapUsersABI, contractAddress)  

        const getData         = await myUsersContract.methods.getUsers().call({from: devAccount})
        let lastData          = getData[getData.length-1];
        lastData              = lastData.id;
        return lastData;    // return id
      }
      getLastUserID().then( data => {
        console.log("Last User's Id = ", data)
        window.location.replace("https://newcotech.tap2sos.com/subsconfirm");
      })
    // ------------- sending email to user ----------------
    await fetch('https://newcotech.tap2sos.com:8080/api/v1/send',{
        method: 'POST',
        body:JSON.stringify({name: name, mail: email, username:username, transactionHash: transaction_hash}),
        headers:{
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
    })
    .catch(error => {
        console.log(error);
        if (error.message === "Network Error"){
          console.log("Server is down .... Network Error Detected")
        }
    }).then(res => {
        if (res.status === "success") {
          console.log("Message Sent");
        } else if (res.status === "fail") {
          console.log("Message failed to send");
        }
        console.log(res);
    });

       
  }

  return (
    <div className="row g-0 auth-wrapper">
    <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
      <div className="auth-background-holder"></div>
      <div className="auth-background-mask"></div>
    </div>

    <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
       <div className="d-flex flex-column align-content-end">
         <div className=" auth-body mx-auto">
           <p className="h5">Δημιουργία Λογαριασμού</p>
           <hr></hr>
           <div className=" auth-form-container text-start">
             <form
               className="auth-form"
               method="POST"
               onSubmit={register}
               autoComplete={"off"}
             >
                <div className="username mb-3">
               
                 <input
                   type="text"
                   className={`form-control ${
                     validate.validate && validate.validate.username
                       ? "is-invalid "
                       : ""
                   }`}
                   id="username"
                   name="username"
                   value={username}
                   placeholder="Όνομα Χρήστη"
                   onChange={(e) => setUser(e.target.value)}
                 />
{ /***"The array is required" message */ }
                 <div
                   className={`invalid-feedback text-start ${
                     validate.validate && validate.validate.username
                       ? "d-block"
                       : "d-none"
                   }`}
                 >
                   {validate.validate && validate.validate.username
                     ? validate.validate.username[0]
                     : ""}
                 </div>
    {/***Ends of message */}             
               </div>

               <div className="name mb-3">
                 <input
                   type="text"
                   className={`form-control ${
                     validate.validate && validate.validate.name
                       ? "is-invalid "
                       : ""
                   }`}
                   id="name"
                   name="name"
                   value={name}
                   placeholder="Όνομα"
                   onChange={(e) => setName(e.target.value)}
                 />

                 <div
                   className={`invalid-feedback text-start ${
                     validate.validate && validate.validate.name
                       ? "d-block"
                       : "d-none"
                   }`}
                 >
                   {validate.validate && validate.validate.name
                     ? validate.validate.name[0]
                     : ""}
                 </div>
               </div>

               <div className="surname mb-3">
              
                 <input
                   type="text"
                   className={`form-control ${
                     validate.validate && validate.validate.surname
                       ? "is-invalid "
                       : ""
                   }`}
                   id="surname"
                   name="surname"
                   value={surname}
                   placeholder="Επώνυμο"
                   onChange={(e) => setSurname(e.target.value)}
                 />

                 <div
                   className={`invalid-feedback text-start ${
                     validate.validate && validate.validate.surname
                       ? "d-block"
                       : "d-none"
                   }`}
                 >
                   {validate.validate && validate.validate.surname
                     ? validate.validate.surname[0]
                     : ""}
                 </div>
               </div>

               <div className="email mb-3">
             
                 <input
                   type="email"
                   className={`form-control ${
                     validate.validate && validate.validate.email
                       ? "is-invalid "
                       : ""
                   }`}
                   id="email"
                   name="email"
                   value={email}
                   placeholder="Email"
                   onChange={(e) => setEmail(e.target.value)}
                 />

                 <div
                   className={`invalid-feedback text-start ${
                     validate.validate && validate.validate.email
                       ? "d-block"
                       : "d-none"
                   }`}
                 >
                   {validate.validate && validate.validate.email
                     ? validate.validate.email[0]
                     : ""}
                 </div>
               </div>

               <div className="medicalCondition mb-3">
                 <textarea
                   rows="1"
                   type="text"
                   className={`form-control ${
                     validate.validate && validate.validate.medicalCondition
                       ? "is-invalid "
                       : ""
                   }`}
                   id="MedicalCondition"
                   name="MedicalCondition"
                   value={medicalCondition}
                   placeholder="Ιατρικές Παθήσεις // Χρήσιμες Πληροφορίες"
                   onChange={(e) => setCondition(e.target.value)}
                 />
               </div>                                                           

            <div className="organDonor mb-3">
               <label> Είστε Δωρητής Οργάνων;   &nbsp; </label>
               <Select 
               placeholder="Επιλογή"
               options={Donor}
               value={Donor.find(obj => obj.value === organDonor)} 
               onChange={handleChange} />
            </div>             

            <div className="bloodtype mb-3">
             <label> Ομάδα Αίματος :  &nbsp; </label>
             <Select 
              placeholder="Επιλογή"
              options={blood} 
              value={blood.find(obj => obj.value === bloodType)} 
              onChange={handleChangeblood}/>
            </div>

          <div className="sex mb-3">
               <label> Φύλο :  &nbsp; </label>
               <Select 
                  placeholder="Επιλογή"
                  options={gender}
                  value={gender.find(obj => obj.value === sexid)} 
                  onChange={handleChangesex} />
            </div>

               <div className="doctorsPhone mb-3">
               Τηλέφωνο Ιατρού:
               <PhoneInput
                  placeholder="Εισάγετε Αριθμό"
		  defaultCountry="GR"
                  value={doctorsPhone}
                  onChange={setdoctorsPhone}/>
               </div>

               <div className="phone mb-3">
                 Tηλέφωνο Έκτακτης Ανάγκης:
                 <PhoneInput
                  placeholder="Εισάγετε Αριθμό"
                  defaultCountry="GR"
		  value={phone}
                  onChange={setPhone}/>
               </div>

               <div className="birth mb-3">
              <label> Ημερομηνία Γέννησης :  &nbsp; </label>       
                 <DatePicker
                  selected={birth}
                  onChange={(date)=>setBday(date)}
                  dateFormat='dd/MM/yyyy'
                  maxDate={new Date()}
                  isClearable
                  showMonthDropdown
                  showYearDropdown
                  scrollableMonthYearDropdown
                  dropdownMode="select"
                  /> 
                
                 <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.birth
                       ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.birth
                      ? validate.validate.birth[0]
                      : ""}
                  </div>  
                </div> 
                <div className="Checkboxes mb-3" >
                <div>
                <input type="checkbox" id="agree" onChange={checkboxHandler1} />
                <label htmlFor="gdpr"> &nbsp;&nbsp; I agree to the</label>
                <label style={{ color: '#5b99ea' }} onClick={togglePopup1}>
                       &nbsp;Privacy Policy</label>
                
    {isOpen1 && <Popup
      content={<>
        <b>Privacy Policy</b>
        <p>
<b>ΕΝΗΜΕΡΩΣΗ ΓΙΑ ΤΗ ΛΕΙΤΟΥΡΓΙΑ ΤΗΣ ΕΦΑΡΜΟΓΗΣ Tap2SOS ΚΑΙ ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΤΩΝ ΧΡΗΣΤΩΝ, ΣΥΝΦΩΝΑ ΜΕ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΤΟΥ ΓΚΠΔ. (άρθρο 13) </b> <br/> <br/>
1. <b>ΣΚΟΠΟΣ</b>  <br/> <br/>

<b>Σκοπός της συλλογής, καταγραφής, αποθήκευσης και ανάκτησης των προσωπικών δεδομένων των χρηστών.
Τα δεδομένα που προαναφέρονται καταγράφονται και αποθηκεύονται με μοναδικό σκοπό την ανάκτησή τους, σε περίπτωση επείγουσας ιατρικής ανάγκης του χρήστη, ή ατυχήματος, ή άλλου συμβάντος,  που μπορεί να απειλήσει την σωματική ή ψυχική του υγεία, σε δημόσιο ή ιδιωτικό χώρο, ή σε οποιαδήποτε άλλη περίπτωση επιλέξει ο ίδιος. </b>
<br/>
Προς ενημέρωση των χρηστών παραθέτουμε τους ιατρικούς ορισμούς για τη διάκριση των δύο συχνότερων και συνηθέστερων καταστάσεων υγειονομικής ανάγκης, που χρήζουν ιατρικής υποστήριξης:
<br/>
<b>Απώλεια συνείδησης ή λιποθυμία:</b> μπορεί να οφείλεται σε καρδιακά ή νευρολογικά αίτια, όπως αγγειακό εγκεφαλικό επεισόδιο, επιληπτική κρίση, υπαραχνοειδή αιμορραγία, κακώσεις κεφαλής, υπερβολική σωματική εξάντληση, αφυδάτωση, υπογλυκαιμία, έντονη συγκίνηση κλπ. Σπανιότερα σε έντονο βήχα ή απώλεια μυϊκού τόνου.
 <br/>
<b>Απώλεια αισθήσεων:</b> αδυναμία απόκρισης σε εξωτερικά ερεθίσματα, αλλαγή ή τροποποίηση της νοητικής κατάστασης ή μειωμένη εγρήγορση, πρέπει να αντιμετωπίζεται ως ιατρικό επείγον. το άτομο δεν μπορεί να απαντήσει σε απλές ερωτήσεις, όπως όνομα, ημερομηνία, τοποθεσία κλπ).
<br/>
<br/>
2. <b>ΕΝΗΜΕΡΩΣΗ ΣΧΕΤΙΚΑ ΜΕ ΤΙΣ ΤΕΧΝΙΚΕΣ ΛΕΠΤΟΜΕΡΕΙΕΣ ΛΕΙΤΟΥΡΓΙΑΣ ΤΟΥ ΠΕΡΙΚΑΡΠΙΟΥ Tap2SOS </b>
<br/>
Το περικάρπιο (μπρασελέ χειρός) Tap2SOS παρέχει τη δυνατότητα <b>πρόσβασης</b> και <b>ανάκτησης</b> των προσωπικών δεδομένων του χρήστη, σε περίπτωση επείγουσας ανάγκης, μέσω της απλής επαφής ή και με ανέπαφη συμπλησίαση, ενός smartphone, ή tablet, ή desktop, που φέρει την τεχνολογία NFC (Near Field Communication). Το περιεχόμενο των στοιχείων εμφανίζεται στην οθόνη μιας από τις παραπάνω συσκευές, υποδηλώνοντας στο άτομο που προστρέχει προς βοήθεια (ή αντιλαμβάνεται) την ιδιαίτερη ανάγκη ή κατάσταση του χρήστη, ώστε να αντιμετωπιστεί το συντομότερο δυνατό και με τον καταλληλότερο δυνατό τρόπο υποστήριξης το ζωτικό του συμφέρον, σε περίπτωση κινδύνου ζωής ή σοβαρής βλάβης για την υγεία.
<br/>
Η επιλογή του είδους, της έκτασης και της κατηγορίας των στοιχείων (δεδομένων), που αποθηκεύονται και ανακτώνται αποτελεί αποκλειστικά προσωπική ευθύνη του χρήστη και συναρτάται με τις ιδιαίτερες ανάγκες της κατάστασης της υγείας του.
Η καταγραφή και αποθήκευση των παραπάνω δεδομένων επιτυγχάνεται μέσω της καινοτόμου τεχνολογίας blockchain, οι δυνατότητες χωρητικότητας στοιχείων είναι απεριόριστες και η αναπαραγωγή τους ακριβής.
<br/>
<b>Σημ. 1/ Η τεχνολογία NFC (Near Field Communication) </b> σημαίνει, ότι δύο συσκευές εξοπλισμένες με μία μικρού εύρους (short range) ασύρματη RFID (Radio Frequency Identification) τεχνολογία, μπορούν να επικοινωνούν και να ανταλλάσσουν πληροφορίες, όταν βρεθούν πολύ κοντά μεταξύ τους (μέσω ενός και μόνο “tap”)
<br/>
<b>Σημ. 2/ Η τεχνολογία blockchain </b> αποτελεί καινοτομία για τις ψηφιακές εφαρμογές στον τομέα της υγείας, εφόσον παρέχει τη δυνατότητα κρυπτογραφημένης αποθήκευσης των δεδομένων, ταχείας πρόσβασης και ανάκτησης των στοιχείων με ασφαλή τρόπο και με προστασία της πληροφοριακής ιδιωτικότητας του ατόμου, διασφαλίζοντας την μοναδικότητα της ταυτοποίησής του μέσω πολλαπλών, επιλεκτικών θωρακίσεων του συστήματος. (multiple addresses, multiple keys, single patient identification)
<br/>
<b>Σημ. 3/ Σε περίπτωση χρήσης της εφαρμογής από ανηλίκους με ηλικία μικρότερη των 15 ετών,</b> η συγκατάθεση υπογράφεται από τον νόμιμο αντιπρόσωπό του, ή τον έχοντα την γονική μέριμνα (Νόμος 4624/2019, κεφ Γ', αρ. 21, παρ. 2.
	</p>
        
      </>}
      handleClose={togglePopup1}
    />}
                <label>.</label>
    
                </div>
                <div>
                <input type="checkbox" id="agree" onChange={checkboxHandler2} />
                <label htmlFor="gdpr"> &nbsp;&nbsp; I agree to the</label>
                <label style={{ color: '#5b99ea' }} onClick={togglePopup2}>&nbsp;Terms and Conditions</label>
                {isOpen2 && <Popup
      content={<>
        <b>Terms and Conditions</b>
	<br/>
        <p>
<b>ΕΝΗΜΕΡΩΣΗ ΓΙΑ ΤΗ ΛΕΙΤΟΥΡΓΙΑ ΤΗΣ ΕΦΑΡΜΟΓΗΣ Tap2SOS ΚΑΙ ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΤΩΝ ΧΡΗΣΤΩΝ, ΣΥΝΦΩΝΑ ΜΕ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΤΟΥ ΓΚΠΔ. (άρθρο 13) </b> <br/> <br/>
1. <b>ΣΚΟΠΟΣ</b>  <br/> <br/>

<b>Σκοπός της συλλογής, καταγραφής, αποθήκευσης και ανάκτησης των προσωπικών δεδομένων των χρηστών.
Τα δεδομένα που προαναφέρονται καταγράφονται και αποθηκεύονται με μοναδικό σκοπό την ανάκτησή τους, σε περίπτωση επείγουσας ιατρικής ανάγκης του χρήστη, ή ατυχήματος, ή άλλου συμβάντος, που μπορεί να απειλήσει την σωματική ή ψυχική του υγεία, σε δημόσιο ή ιδιωτικό χώρο, ή σε οποιαδήποτε άλλη περίπτωση επιλέξει ο ίδιος. </b>
<br/> 
Προς ενημέρωση των χρηστών παραθέτουμε τους ιατρικούς ορισμούς για τη διάκριση των δύο συχνότερων και συνηθέστερων καταστάσεων υγειονομικής ανάγκης, που χρήζουν ιατρικής υποστήριξης:
<br/>
<b>Απώλεια συνείδησης ή λιποθυμία:</b> μπορεί να οφείλεται σε καρδιακά ή νευρολογικά αίτια, όπως αγγειακό εγκεφαλικό επεισόδιο, επιληπτική κρίση, υπαραχνοειδή αιμορραγία, κακώσεις κεφαλής, υπερβολική σωματική εξάντληση, αφυδάτωση, υπογλυκαιμία, έντονη συγκίνηση κλπ. Σπανιότερα σε έντονο βήχα ή απώλεια μυϊκού τόνου. 
 <br/>
<b>Απώλεια αισθήσεων:</b> αδυναμία απόκρισης σε εξωτερικά ερεθίσματα, αλλαγή ή τροποποίηση της νοητικής κατάστασης ή μειωμένη εγρήγορση, πρέπει να αντιμετωπίζεται ως ιατρικό επείγον. (το άτομο δεν μπορεί να απαντήσει σε απλές ερωτήσεις, όπως όνομα, ημερομηνία, τοποθεσία κλπ).
<br/>
<br/>
2. <b>ΕΝΗΜΕΡΩΣΗ ΣΧΕΤΙΚΑ ΜΕ ΤΙΣ ΤΕΧΝΙΚΕΣ ΛΕΠΤΟΜΕΡΕΙΕΣ ΛΕΙΤΟΥΡΓΙΑΣ ΤΟΥ ΠΕΡΙΚΑΡΠΙΟΥ Tap2SOS </b>
<br/>
Το περικάρπιο (μπρασελέ χειρός) Tap2SOS παρέχει τη δυνατότητα <b>πρόσβασης</b> και <b>ανάκτησης</b> των προσωπικών δεδομένων του χρήστη, σε περίπτωση επείγουσας ανάγκης, μέσω της απλής επαφής ή και με ανέπαφη συμπλησίαση, ενός smartphone, ή tablet, ή desktop, που φέρει την τεχνολογία NFC (Near Field Communication). Το περιεχόμενο των στοιχείων εμφανίζεται στην οθόνη μιας από τις παραπάνω συσκευές, υποδηλώνοντας στο άτομο που
προστρέχει προς βοήθεια (ή αντιλαμβάνεται) την ιδιαίτερη ανάγκη ή κατάσταση του χρήστη, ώστε να αντιμετωπιστεί το συντομότερο δυνατό και με τον καταλληλότερο δυνατό τρόπο υποστήριξης το ζωτικό του συμφέρον, σε περίπτωση κινδύνου ζωής ή σοβαρής βλάβης για την υγεία.
Η επιλογή του είδους, της έκτασης και της κατηγορίας των στοιχείων (δεδομένων), που αποθηκεύονται και ανακτώνται αποτελεί αποκλειστικά <b> προσωπική ευθύνη του χρήστη</b> και συναρτάται με τις ιδιαίτερες ανάγκες της κατάστασης της υγείας του. 
<br/>
Η καταγραφή και αποθήκευση των παραπάνω δεδομένων επιτυγχάνεται μέσω της καινοτόμου τεχνολογίας blockchain, οι δυνατότητες χωρητικότητας στοιχείων είναι απεριόριστες και η αναπαραγωγή τους ακριβής. 
<br/>
<b>Σημ. 1/ Η τεχνολογία NFC (Near Field Communication) </b>σημαίνει, ότι δύο συσκευές εξοπλισμένες με μία μικρού εύρους (short range) ασύρματη RFID  (Radio Frequency Identification) τεχνολογία, μπορούν να επικοινωνούν και να ανταλλάσσουν πληροφορίες, όταν βρεθούν πολύ κοντά μεταξύ τους (μέσω ενός και μόνο “tap”). 
<br/>
<b>Σημ. 2/ Η τεχνολογία blockchain </b>αποτελεί καινοτομία για τις ψηφιακές εφαρμογές στον τομέα της υγείας, εφόσον παρέχει τη δυνατότητα κρυπτογραφημένης αποθήκευσης των δεδομένων, ταχείας πρόσβασης και ανάκτησης των στοιχείων με ασφαλή τρόπο και με προστασία της πληροφοριακής ιδιωτικότητας του ατόμου, διασφαλίζοντας την μοναδικότητα της ταυτοποίησής του μέσω πολλαπλών, επιλεκτικών θωρακίσεων του συστήματος. (multiple addresses, multiple keys, single patient identification). 
<br/>
<b>Σημ. 3/ Σε περίπτωση χρήσης της εφαρμογής από ανηλίκους με ηλικία μικρότερη των 15 ετών,</b> η συγκατάθεση υπογράφεται από τον νόμιμο αντιπρόσωπό του, ή τον έχοντα την γονική μέριμνα (Νόμος 4624/2019, κεφ Γ', αρ. 21, παρ. 2.
	</p>
        
      </>}
      handleClose={togglePopup2}
    />}
                <label>.</label>
                </div></div>
               
               <div className="text-center">
                 <button
                    onClick={register}
                    
                    disabled={disable}
                    
                   
                    type="submit"
                    className="btn btn-primary w-100 theme-btn mx-auto"
                 >
                   {loading && <i className="fa fa-refresh fa-spin "/>}
                   Sign Up
                 </button>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default Register;
